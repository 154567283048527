import React, { useState } from "react";
// import { ToastContainer } from "react-toastify";
import ReactImageMagnify from "react-image-magnify";
import { Form, InputGroup, Tabs, Tab } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMinus,
  faPlus,
  faUser,
  faReplyAll,
} from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.css";
import "./nikkah.css"; // Assume custom CSS is used
import { useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Nikkah = () => {
  const Navigate=useNavigate();
  const [activeColor, setActiveColor] = useState(null);
  const [selectedSize, setSelectedSize] = useState("");
  const [count, setCount] = useState(1);
  const [imagePreview, setImagePreview] = useState(null);
  // const [newComment, setNewComment] = useState("");
  const [activePerson, setActivePerson] = useState(null); // To track the selected person option
  const [NikkahImages, setNikkahImages] = useState([]);
  const [mainimage, setMainimage] = useState();
  const [Selectedprice,setSelectedprice] = useState();
  const[ActiveSize,setActiveSize]=useState();
  const [saveImage, setSaveImage] = useState();

  useEffect(() => {
    postNikkahFrames();
  }, []);



  const [commentlist, setcommentlist] = useState([]);

  const CommentList = async () => {
    try {
      const Commentshow = await axios.post(
        "https://lunarsenterprises.com:3008/newlook/list-comment",{ size_id:"11"}
      );
      setcommentlist(Commentshow.data.list);
      console.log("Commentshow.data", Commentshow.data);
    } catch (error) {}
  };

  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");

  const handleCommentChange = (event) => {
    setNewComment(event.target.value);
  };

  const handleCommentSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:3008/newlook/add-comment",
        { comment: newComment , size_id:"11"}
      );
      if (response.data.result) {
        toast.success("Comment submitted successfully!");
        setNewComment("");
        CommentList(); // Reload comments
      } else {
        toast.error("Failed to submit comment.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    }
  };

  const productName = "Nikkah Frames";
  const stock = 10;
  const totalPrice = 1000;
  const totalDiscount = 800;
  const final = 20;
  const productSizes = [
    { s_id: "A3", s_name: "Small" },
    { s_id: "A4", s_name: "Medium" },
  ];
  const description = "High-quality material, Comfortable, Stylish";
  // const commentlist = [
  //   { c_comment: "Great product!", c_comment_replay: "Thank you!" },
  //   { c_comment: "Fast shipping.", c_comment_replay: "Glad to hear that!" },
  // ];
  const Viewapi = [
    { price: "200", size: "A3" },
    {  price: "400", size: "A4" },
  ];


  const moveToBuyNow = () => {
    const productDatas = {
      // id: s_id, 
      product_type : "special_frame",
      framename: "NikkahFrames",
      image:saveImage,
      quantity: count,
      // price: productPrice,
      // totalPrice: totalPrice,
      // color: selectedColor,
      // colorid: colorid,
      // totalDiscount: totalDiscount,
      // imagePreview: imagePreview,
      selectedSize: selectedSize,
      // colorid: colorid,
    }
    Navigate("/checkout", productDatas);
    };

  const handleImageClick = (img) => {
    setMainimage(img.ni_image);
  };
   const handleSizeChange= (price) => {
    setSelectedprice(price.price);
    setActiveSize(price.size);

  };
  // const handleSizeChange = (e) => setSelectedSize(e.target.value);
  const decrement = () => setCount((prev) => Math.max(prev - 1, 1));
  const increment = () => setCount((prev) => prev + 1);
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSaveImage(file);
    if (file) setImagePreview(URL.createObjectURL(file));
  };
  // const handleCommentChange = (e) => setNewComment(e.target.value);
  // const handleCommentSubmit = (e) => {
  //   e.preventDefault();
  //   if (newComment) {
  //     alert(`New Comment: ${newComment}`);
  //     setNewComment("");
  //   }
  // };

  const postNikkahFrames = async (data) => {
    const url = "https://lunarsenterprises.com:3008/newlook/list/nikkah-images";

    try {
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json", // Use 'application/x-www-form-urlencoded' if the server expects form data
        },
      });

      const images = response.data.list;
      setNikkahImages(images);
      // Set the first image as the default main image if available
      if (images.length > 0 && !mainimage) {
        setMainimage(images[0].ni_image);
      }
    } catch (error) {
      console.error(
        "Error posting photomerge images:",
        error.response ? error.response.data : error.message
      );
    }
  };

  console.log(NikkahImages, "nikkahImages");

  return (
    <>
      <ToastContainer />
      <div className="container product-display mb-5">
        <div className="selected-product">
          <div className="product-images">
            <ReactImageMagnify
              {...{
                smallImage: {
                  alt: productName,
                  isFluidWidth: true,
                  src:'https://lunarsenterprises.com:3008/' + mainimage,
                },
                largeImage: {
                  src: 'https://lunarsenterprises.com:3008/' + mainimage,
                  width: 1200,
                  height: 1800,
                },
              }}
            />
            <div className="sub-images">
              {NikkahImages.length > 0 ? (
                NikkahImages.map((img, index) => (
                  <div key={index}>
                    <img
                      src= {`https://lunarsenterprises.com:3008/${img.ni_image}`}
                      alt={`Size: ${img.size}`}
                      className="sub-image"
                      onClick={() => handleImageClick(img)}
                      style={{
                        border: activeColor === img ? "2px solid red" : "",
                      }}
                    />
                  </div>
                ))
              ) : (
                <p>No images available</p>
              )}
            </div>
          </div>
          <div className="product-details">
            <div className="d-flex">
              <h1>{productName}</h1>
              {/* <h4 className="final-discount">-{final}%</h4> */}
            </div>
            {/* <h6 style={{ color: "brown" }}>
              (Stock Available: {stock - count})
            </h6> */}

            <div className="selected-product-price">
              {/* <del className="selected-price">
                <span>Rs</span>
                {totalPrice}
              </del> */}
              <div className="selected-discounted-price">
                <span>Rs</span>
                {Selectedprice ? Selectedprice : 200}
              </div>
            </div>

            {/* Variety Options */}
            <div className="color-selection">
              <h6>Variety Options</h6>
              <div className="colors">
                {Viewapi.map((img, index) => (
                  <div
                    key={index}
                    className="color-box"
                    onClick={() =>handleSizeChange(img)}
                    style={{
                      cursor: "pointer",
                      borderColor: ActiveSize === img ? "red" : "black",
                    }}
                  >
                    {img.size} {/* Display number of persons */}
                  </div>
                ))}
              </div>
            </div>

            {/* Product Size Selection */}
            <div>
              {/* <div className="color-selection">
                <h6>Select Product Size</h6>
                <InputGroup>
                  <Form.Select onChange={handleSizeChange} value={selectedSize}>
                    <option value="">Select a size</option>
                    {productSizes.map((size) => (
                      <option key={size.s_id} value={size.s_id}>
                        {size.s_name}
                      </option>
                    ))}
                  </Form.Select>
                </InputGroup>
                {selectedSize && <p>Selected Size: {selectedSize}</p>}
              </div> */}
            </div>

            {/* Quantity Selector */}
            <p>Quantity</p>
            <div className="counting-box">
              <button onClick={decrement}>
                <FontAwesomeIcon icon={faMinus} />
              </button>
              <div className="count">{count}</div>
              <button onClick={increment}>
                <FontAwesomeIcon icon={faPlus} />
              </button>
            </div>

            {/* Image Upload */}
            <div className="image-upload">
              <h6>Upload an Image</h6>
              <InputGroup>
                <Form.Control
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </InputGroup>
              {imagePreview && (
                <div className="image-preview" style={{ marginTop: "20px" }}>
                  <h6>Image Preview:</h6>
                  <img
                    src={imagePreview}
                    alt="Preview"
                    style={{
                      maxWidth: "300px",
                      maxHeight: "300px",
                      borderRadius: "5px",
                      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
                    }}
                  />
                </div>
              )}
            </div>

            {/* <button className="addtocart">Add to Cart</button> */}
            <button className="addtocart" 
            onClick={moveToBuyNow}
            >Buy Now</button>
          </div>
        </div>

        {/* Product Tabs (Description, Comments, Review) */}
        <div className="container describ">
          <Tabs
            defaultActiveKey="describe"
            id="product-tabs"
            className="custom-tabs"
          >
            <Tab
              eventKey="describe"
              title="Description"
              className="custom-tab-content"
            >
              <ul>
                {description.split(",").map((item, index) => (
                  <li key={index}>{item.trim()}</li>
                ))}
              </ul>
            </Tab>
            <Tab
              eventKey="Comments"
              title="Comments"
              className="custom-tab-content"
            >
              <div className="comment-section">
                <form onSubmit={handleCommentSubmit}>
                  <textarea
                    value={newComment}
                    onChange={handleCommentChange}
                    placeholder="Write a comment..."
                  ></textarea>
                  <button type="submit">Submit</button>
                </form>
              </div>
            </Tab>
            <Tab
              eventKey="Review"
              title="Review"
              className="custom-tab-content"
            >
              <ul>
                {commentlist.map((comment, index) => (
                  <li key={index}>
                    <div className="userside">
                      <FontAwesomeIcon icon={faUser} />
                      <h6 className="user">User:</h6>
                    </div>
                    <div className="comment">{comment.c_comment}</div>
                    <div className="adminside">
                      <FontAwesomeIcon
                        style={{ marginLeft: "15px" }}
                        icon={faReplyAll}
                      />
                      <h6 className="user-reply">Reply:</h6>
                    </div>
                    <div className="admin-comment">
                      {comment.c_comment_replay}
                    </div>
                  </li>
                ))}
              </ul>
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default Nikkah;
