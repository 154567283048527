import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import "./mug.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Image from "../../Assets/noimage.jpg";
import Form from "react-bootstrap/Form";

function Mug() {
  const [mug, setMug] = useState([]); // Original mug data
  const [sortedMug, setSortedMug] = useState([]); // State for sorted mugs
  const [sortOrder, setSortOrder] = useState(""); // State for filter selection
  const navigate = useNavigate();

  const buttonClick = (p_id) => {
    console.log(p_id);
    navigate(`/product/${p_id}`);
  };

  useEffect(() => {
    muglisting();
  }, []);

  useEffect(() => {
    sortMugs(); // Sort mugs when sortOrder changes
  }, [sortOrder, mug]);

  // API call to fetch mugs
  const muglisting = async () => {
    try {
      const mugParameter = { size: 13, role:"user" };
      const response = await axios.post(
        "https://lunarsenterprises.com:3008/newlook/list-products",
        mugParameter
      );
      // Ensure that you check the response structure and set mug appropriately
      if (response.data.list && Array.isArray(response.data.list)) {
        setMug(response.data.list);
      } else {
        setMug([]); // Set mug to an empty array if no products are found
      }
      console.log(response.data.list, "muglistingmuglisting");
      console.log(response, "resmug");
    } catch (error) {
      console.log(`error fetching mug list:`, error);
      setMug([]); // Set mug to an empty array in case of an error
    }
  };

  // Sort mugs based on the selected filter
  const sortMugs = () => {
    let sorted = [...mug];
    if (sortOrder === "1") {
      // High to Low
      sorted.sort((a, b) => b.price - a.price);
    } else if (sortOrder === "2") {
      // Low to High
      sorted.sort((a, b) => a.price - b.price);
    }
    setSortedMug(sorted);
  };

  return (
    <>
      <div className="mug-container">
        <div className="container">
          <div className="mug-heading-lines row">
            <div className="col-md-4"></div>
            <div className="col-md-4">
              <h3 className="mug-heading text-center mx-auto">CUSTOMIZED MUG</h3>
            </div>
            <div className="col-md-2">
              <Form.Select
                className="framefilterdropdown"
                aria-label="Filter select"
                onChange={(e) => setSortOrder(e.target.value)}
              >
                <option value="">Filter</option>
                <option value="1">High to Low</option>
                <option value="2">Low to High</option>
              </Form.Select>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>

        <div className="container">
          <Row className="justify-content-center">
            {mug.length === 0 ? (
              <Col xs={12}>
                <h1 className="text-center" style={{ color: "black" }}>
                   Products not Available
                </h1>
              </Col>
            ) : (
              sortedMug.map((mug, index) => (
                <Col lg={3} md={4} sm={6} xs={12} className="mb-4" key={index}>
                  <Card className="mx-auto product-card">
                    <div className="image-content">
                      <div className="d-flex justify-content-between align-items-center mb-2">
                        {mug.stock <= 0 ? (
                          <p
                            className="text-left"
                            style={{ backgroundColor: "black", color: "white" }}
                          >
                            Sold out
                          </p>
                        ) : (
                          ""
                        )}
                        {mug.discount >= 0 ? (
                          <p
                            className="text-right"
                            style={{ backgroundColor: "red", color: "white" }}
                          >
                            -{mug.discount}%
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <Card.Img
                        variant="top"
                        className="mug-card"
                        src={
                          mug.image[0]
                            ? `https://lunarsenterprises.com:3008/${mug.image[0]?.image}`
                            : Image
                        }
                      />
                    </div>
                    <Card.Body>
                      <div className="mugmain">
                        <div className="mug-name">
                          <h6>{mug.name}</h6>
                        </div>
                      </div>
                      <div className="mug-price-details">
                        <del className="discount-price">
                          <span>Rs</span>
                          {mug.price}
                        </del>
                        <div className="price" style={{ color: "red" }}>
                          <span>Rs</span>
                        
                          {mug.discount_price}
                        </div>
                      </div>
                      <Button
                        style={{ width: "100%", backgroundColor: "#219C90" }}
                        variant="primary"
                        onClick={() => buttonClick(mug.p_id)}
                      >
                        Buy Now
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              ))
            )}
          </Row>
        </div>
      </div>
    </>
  );
}

export default Mug;
